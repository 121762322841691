<template>
    <section v-if="films.length > 0" class="cinema_section">
        <div class="content_section">
            <div class="inner">
                <header class="content_header">
                    <h2><span>Cinema Bonobo</span></h2>
                    <h4>Películas especiales relacionadas con el mundo del sexo y que por diversos motivos tienen dificultades para la proyección o el visionado en plataformas convencionales (las puritanas de toda la vida).</h4>
                </header>
                <article class="content_category cinema_category mb30">
                    <div class="grid__motor grid__motor_col_2">
                        <router-link v-for="f in films" :key="f.url" class="work_link" :to="`cinema_bonobo/${f.url}`">
                            <div class="work_thumb">
                                <div class="work_img" :style="{'background-image': 'url('+f.image+')'}"></div>
                            </div>
                            <div class="work_thumb_text">
                                <h4 class="work_title">
                                    <div class="hover_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                                    </div>
                                    {{ f.title }}</h4>
                                <h5 class="work_author">{{ f.directors }}</h5>
                            </div>
                        </router-link>
                    </div>
                </article>
                <router-link to="/cinema_bonobo" class="btn small graphic white right">
                    Ver todo
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: "filmshome",
    data: function() {
        return {
            films: []
        }
    },
    created: function(){
        axios
        .get(`${process.env.VUE_APP_URL}films`)
        .then(response => {
            let rs = response.data;
            console.log(rs);
            if(rs.state == 'OK'){
                this.films = rs.data;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
}
</script>