<template>
    <div class="content_block" v-if="not">
        <div class="grid grid_group grid_pad flex_align_center">
        <div class="grid_col grid_col_phi_2_3">
                <div class="text_block">
                <h2 class="feature_heading">{{not.title}}</h2>
                <p v-html="not.shortdesc"></p>
            </div>
        </div>
        <div class="grid_col grid_col_phi_1_3">
            <div class="video_container mb30">
            <img :alt="not.title" :src="not.img" />
            <!--<iframe src="https://premiosbonobo.videoweb.tv/embed.php?id=125&amp;embedded=0&amp;nobthq=1" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>-->
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "noticia",
    data: function(){
        return{
            not: null
        }
    },
    created: function() {
        axios
            .get(process.env.VUE_APP_URL+'notices', {
                params:{
                    active: 1,
                    'orderBy[column]': 'actived_at',
                    'orderBy[order]': 'desc',
                    category: 20,
                    limit: 1
                }
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK')
                    this.not = rs.data[0];
            })
            .catch(error => {
                console.log(error);
            })
    }
}
</script>