import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueGtag from "vue-gtag";

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false
if (process.env.VUE_APP_GTAG) {
    Vue.use(VueGtag, {
        config: { id: "UA-8508554-44" }
    }, router);
}

Vue.directive('click-outside', {
    inserted(el, binding) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

function start() {
    new Vue({
        router,
        render: h => h(App)
    }).$mount('.main_wrapper');
}

setInterval(function() {
    if (localStorage.getItem('user')) {
        axios
            .get(process.env.VUE_APP_URL + 'isLogued')
            .then(response => {
                let rs = response.data;
                if (rs.state != 'OK'){
                    localStorage.removeItem('user');
                    location.reload();
                }
            })
            .catch(error => {
                localStorage.removeItem('user');
            });
    }
}, 60000);

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user');
    if(token){
        config.headers["API-KEY"] = token;
    }
    return config;
});

axios
    .get(process.env.VUE_APP_URL + 'actualEdition')
    .then(function(response) {
        let rs = response.data;
        if (rs.state == 'OK')
            localStorage.setItem('edition', rs.data);
        else
            console.log('No se recibio bien');
    })
    .catch(error => {
        console.log(error);
    })
    .finally(() => start());

import '@/assets/css/style.css'
import './registerServiceWorker'
