import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/obras/:id',
        name: 'Obra',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Obra',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Obra.vue')
            }
        ]
    },
    {
        path: '/extras/:id',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Extra',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Obra.vue')
            }
        ]
    },
    {
        path: '/autor/:id',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Autor',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Autor.vue')
            }
        ]
    },
    {
        path: '/seccion/:secion',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Obras',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Obras.vue')
            }
        ]
    },
    {
        path: '/old/:year',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Anteriores',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Anteriores.vue')
            }
        ]
    },
    {
        path: '/legal',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Legal',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Legal.vue')
            }
        ]
    },
    {
        path: '/eliminar_cuenta/:link',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Eliminar Cuenta',
                component: () => import(/* webpackChunkName: "about" */ '@/views/EliminarCuenta.vue')
            }
        ]
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Signin.vue')
            }
        ]
    },
    {
        path: '/contacto',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Contacto',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Contacto.vue')
            }
        ]
    },
    {
        path: '/participa',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Participa',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Participa.vue')
            }
        ]
    },
    {
        path: '/noticias',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Noticias',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Noticias.vue')
            }
        ]
    },
    {
        path: '/noticias/:slug',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Noticia',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Noticia.vue')
            }
        ]
    },
    /*{
      path: '/live',
      component: () => import('@/components/Cabecera.vue'),
      children: [
        {
          path: '',
          name: 'Live',
          component: () => import('@/views/Live.vue')
        }
      ]
    },*/
    {
        path: '/galeria',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Galeria',
                component: () => import('@/views/Galeria.vue')
            }
        ]
    },
    {
        path: '/galeria_presentacion',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Galeria',
                component: () => import('@/views/GaleriaPresentacion.vue')
            }
        ]
    },
    {
        path: '/recuperar/:slug?',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Recuperar Password',
                component: () => import(/* webpackChunkName: "about" */ '@/views/RecoverPassword.vue')
            }
        ]
    },

    {
        path: '/verificar/:slug',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Verificar Usuario',
                component: () => import(/* webpackChunkName: "about" */ '@/views/Verificar.vue')
            }
        ]
    },
    {
        path: '/cinema_bonobo/:url',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                name: 'Pelicula',
                path: '',
                component: () => import(/* webpackChunkName: "about" */ '@/views/peliculas/Pelicula.vue')
            }
        ]
    },
    {
        path: '/cinema_bonobo',
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                name: 'Peliculas',
                path: '',
                component: () => import(/* webpackChunkName: "about" */ '@/views/peliculas/Peliculas.vue')
            }
        ]
    },
    {
        path: "*",
        component: () => import(/* webpackChunkName: "about" */ '@/components/Cabecera.vue'),
        children: [
            {
                path: '',
                name: 'Not Found',
                component: () => import("@/views/NotFound.vue")
            }
        ]
    }
]

const router = new VueRouter({
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash){
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        selector: to.hash,
                        behavior: 'smooth',
                    })
                }, 1000)
            })
        } else {
            return { x: 0, y: 0 }
        }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
