<template>
    <section class="video_home" style="z-index: 0;">
        <div class="inner">
            <div class="video_home_wrapper">
                <div class="video_home_container">
                    <div id="textoCabecera" class="video_home_intro">
                    <h2>
                        <span>9<sup>a</sup> Edición</span>
                        <span>Premios</span>
                        <span>Bonobo</span>
                    </h2>
                    </div>
                    <video id="videoCabecera" preload="none" autoplay="" muted="" loop="" style="top: 0%">
                        <source src="https://imgdb.premiosbonobo.com/fondo/NovenaEdicionSalida.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "fondo",
    created () {
        window.addEventListener('scroll', this.scrollFunc);
        this.ecuacion = -40 / window.innerHeight;
    },
    destroyed () {
        window.removeEventListener('scroll', this.scrollFunc);
    },
    methods:{
        scrollFunc(event){
            if(window.scrollY < 1){
                document.querySelector('#textoCabecera').style.top = (window.scrollY*-0.5) + 'px';
                document.querySelector('#videoCabecera').style.top = this.ecuacion * window.scrollY + '%';
            }
        }
    },
    mounted: function(){
        this.scrollFunc();
        document.querySelector('#videoCabecera').play();
    }
}
</script>