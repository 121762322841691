<template>
	<section class="intro_main">
		<div class="content_section">
			<div class="inner">
				<article class="content_category intro_features grid__motor grid__motor_col_3">

					<div class="intro_features_row">
						<div class="intro_features_row_item left item_visual">
							<router-link to="/galeria_presentacion">
								<h2>Galería de fotografías de la presentación de la 9<sup>ª</sup> Edición Premios Bonobo</h2>
								<img src="@/assets/images/fotos_presentacion_9a_edcicion.jpg"/>
							</router-link>
						</div>
						<div class="intro_features_row_item left item_text">
							<h3 class="feature_sub_heading">
								9<sup>ª</sup> Edición Premios Bonobo
							</h3>
							<h2 class="feature_heading">
								Galería de fotografías de la presentación de la 9<sup>ª</sup> Edición Premios Bonobo
							</h2>
							<router-link to="/galeria_presentacion" class="btn xsmall graphic grey right mb20 mr20">
								Ver ahora
								<svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
									<path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
								</svg>
							</router-link>
						</div>
					</div>

					<div class="intro_features_row">
						<div class="intro_features_row_item left item_visual">
							<router-link to="/extras/21">
								<h2>Presentación de la 9<sup>ª</sup> Edición Premios Bonobo</h2>
								<img src="@/assets/images/presentacion_9a_edicion.jpg"/>
							</router-link>
						</div>
						<div class="intro_features_row_item left item_text">
							<h3 class="feature_sub_heading">
								9<sup>ª</sup> Edición Premios Bonobo
							</h3>
							<h2 class="feature_heading">
								Presentación de la 9ª Edición Premios Bonobo
							</h2>
							<router-link to="/extras/21" class="btn xsmall graphic grey right">
								Ver ahora
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
									<path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
								</svg>
							</router-link>
						</div>
					</div>

					<div class="intro_features_row">
						<div class="intro_features_row_item left item_visual">
							<router-link to="/extras/20">
								<h2>Aula Bonobo</h2>
								<img src="@/assets/images/aula_bonobo.jpg"/>
							</router-link>
						</div>
						<div class="intro_features_row_item left item_text">
							<h3 class="feature_sub_heading">
								9<sup>ª</sup> Edición Premios Bonobo
							</h3>
							<h2 class="feature_heading">
								Aula Bonobo
							</h2>
							<router-link to="/extras/20" class="btn xsmall graphic grey right"
								>Ver ahora<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
									/></svg
							></router-link>
						</div>
					</div>



				</article>
				<div class="grid grid_group grid_pad flex_center">
					<div class="grid_col grid_col_3_4">
						<!--<noticia />-->
					</div>
					<div class="grid_col grid_col_3_4">
						<div class="banner_block">
							<a href="https://shopohs.es/artistas/premios_bonobo" target="_blank" title="Ya puedes comprar los libros de los Premios Bonobo" class="banner_link banner_supermegabanner">
								<img src="@/assets/images/libros_bonobos_980x90_std.jpg" alt="Ya puedes comprar los libros de los Premios Bonobo"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Noticia from "@/components/home/Noticia.vue";

export default {
	name: "edicion",
	components: {
		Noticia,
	},
};
</script>
