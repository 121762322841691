<template>
    <div class="grid__motor grid__motor_col_5">
        <router-link :to="`/${categoria.extra ? 'extras' : 'obras'}/${obr.id}`"  v-for="obr in obras" :key="obr.id" class="work_link">
            <div class="work_thumb">
                <b v-if="obr.voted" class="voted_icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"></path></svg></b>
                <div v-if="!categoria.extra && !categoria.professional && ![13,14].includes(categoria.id)" class="vote_stats">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"></path></svg>{{obr.votes}}
                </div>
                <div class="work_img" :style="{'background-image': 'url('+obr.img+')'}"></div>
            </div>
            <div class="work_thumb_text">
                <h4 class="work_title">
                    <div class="hover_icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                    {{obr.title}}
                </h4>
                <h5 v-if="!categoria.extra" class="work_author">
                    {{obr.participant.name+" "+obr.participant.lastname}}
                </h5>
            </div>
        </router-link>
    </div>
</template>

<script>

export default {
    name: "obra",
    props:{
        categoria: Object,
        old: String,
        works: Array,
        customRandom: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return{
            obras: []
        }
    },
    created: function(){
        if(this.works){
            if(!this.customRandom){
                this.works = this.works.sort(() => Math.random());
            }else{
                //first this edition, then random
                this.works = this.works.sort((w, w2) => {
                    if(w.edition == 2025 && w2.edition == 2025){
                        if(Date.parse(w.actived_at) > Date.parse(w2.actived_at)) return -1;
                        else return 1;
                    }
                    else if(w.edition == 2025) return -1;
                    else if(w2.edition == 2025) return 1;
                    else return Math.random() > 0.5 ? 1 : -1;
                });
            }
            this.obras = this.works.slice(0, 10);
        }
    }
}
</script>